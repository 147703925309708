import * as React from 'react';
import { TextInput } from 'react-admin';
import { Box, Typography } from '@mui/material';

export const ExpectedValuesStep = (props: any) => {
	return (
		<>
			<Typography
				variant='h6'
				gutterBottom
				style={{
					borderBottom: '1px solid',
					width: '100%',
					marginBottom: '25px'
				}}
			>
				Erwartete Werte festlegen/bearbeiten. Diese Werte werden zur
				automatischen Bestimmung der CSS-Selektoren verwendet
			</Typography>
			<Box>
				<TextInput
					label='Wert für Name'
					source='expectedName'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Manufacturer'
					source='expectedManufacturer'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Price'
					source='expectedPrice'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Currency'
					source='expectedCurrency'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für ArticleNumberOne'
					source='expectedArticleNumberOne'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für ArticleNumberTwo'
					source='expectedArticleNumberTwo'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für ArticleNumberThree'
					source='expectedArticleNumberThree'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für ImgUrl'
					source='expectedImgUrl'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für StockStatus'
					source='expectedStockStatus'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für DeliveryTime'
					source='expectedDeliveryTime'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Description'
					source='expectedDescription'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Brand'
					source='expectedBrand'
					fullWidth
					inputProps={{ maxLength: 200 }}
				/>
				<TextInput
					label='Wert für Rating Value'
					source='expectedRatingValue'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Rating Count'
					source='expectedRatingCount'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Amount 1'
					source='expectedAmount1'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Unit 1'
					source='expectedUnit1'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Amount und Unit 1'
					source='expectedAmountUnit1'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Amount 2'
					source='expectedAmount2'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Unit 2'
					source='expectedUnit2'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Amount und Unit 2'
					source='expectedAmountUnit2'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Category'
					source='expectedCategory'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				<TextInput
					label='Wert für Breadcrumbs'
					source='expectedBreadcrumbs'
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>
				{/* <TextInput label="Wert für Suggested Retail Price (UVP / AVP)" source="expectedSrp" fullWidth/>*/}
				{/*            <TextInput label="Wert für Amount" source="expectedAmount" fullWidth/>
                    <TextInput label="Wert für NormalizedPrice" source="expectedNormalizedPrice" fullWidth/>
                    <TextInput label="Wert für Unit" source="expectedUnit" fullWidth/>
                    <TextInput label="Wert für Multiplier" source="expectedMultiplier" fullWidth/>
                    <TextInput label="Wert für ContainerAmount" source="expectedContainerAmount" fullWidth/>*/}
			</Box>
		</>
	);
};
