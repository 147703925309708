import * as React from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { TextInput } from 'react-admin';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

export const SchemaSelectorFinderStep = (props: any) => {
	const { getValues, setValue } = useFormContext();
	const [isLoading, setIsLoading] = useState(false);
	const handleSearch = () => {
		setIsLoading(true);
		const formData = getValues();

		axios
			.post(
				process.env.REACT_APP_HUB_API_URL +
					'/shops/get-expected-values-and-schema-selectors',
				{ formData: formData }	, { withCredentials: true }
			)
			.then((response) => {
				for (const property in response.data.selectors) {
					if (property == 'breadCrumbs') {
						setValue(
							'selectorBreadcrumbs',
							response.data.selectors['breadCrumbs']
						);
					} else {
						setValue(
							'selector' +
								property.charAt(0).toUpperCase() +
								property.slice(1),
							response.data.selectors[property]
						);
					}
				}
				for (const property in response.data.expectedResponses) {
					if (property == 'breadCrumbs') {
						setValue(
							'expectedBreadcrumbs',
							response.data.expectedResponses['breadCrumbs']
						);
					} else {
						setValue(
							'expected' +
								property.charAt(0).toUpperCase() +
								property.slice(1),
							response.data.expectedResponses[property]
						);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<>
			<Typography
				variant='h6'
				gutterBottom
				sx={{ borderBottom: '1px solid', marginBottom: '25px' }}
			>
				Auf "Selektoren Suchen" klicken, um automatisch Schema
				Selektoren zu finden.
			</Typography>
			<Button
				variant={'contained'}
				sx={{ mb: 2 }}
				onClick={handleSearch}
				disabled={isLoading}
			>
				Selektoren Suchen{' '}
				{isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
			</Button>
			<Typography variant='body1' sx={{ marginBottom: 1 }}>
				Die Selektoren können im 4 Schritt bearbeitet werden.
			</Typography>
			<Grid
				container
				spacing={1}
				sx={{
					'& .MuiFormControl-root': { width: '100%' },
					'& .MuiInputBase-root': {
						color: '#000000de',
						backgroundColor: '#0000000a !important',
						'& input': { WebkitTextFillColor: 'black' }
					}
				}}
			>
				<Grid item xs={6}>
					<TextInput
						label='Selektor für Name'
						source='selectorName'
						disabled
					/>
					<TextInput
						label='Selektor für Manufacturer'
						source='selectorManufacturer'
						disabled
					/>
					<TextInput
						label='Selektor für Price'
						source='selectorPrice'
						disabled
					/>
					<TextInput
						label='Selektor für Currency'
						source='selectorCurrency'
						disabled
					/>
					<TextInput
						label='Selektor für ArticleNumberOne'
						source='selectorArticleNumberOne'
						disabled
					/>
					<TextInput
						label='Selektor für ArticleNumberTwo'
						source='selectorArticleNumberTwo'
						disabled
					/>
					<TextInput
						label='Selektor für ArticleNumberThree'
						source='selectorArticleNumberThree'
						disabled
					/>
					<TextInput
						label='Selektor für ImgUrl'
						source='selectorImgUrl'
						disabled
					/>
					<TextInput
						label='Selektor für StockStatus'
						source='selectorStockStatus'
						disabled
					/>
					<TextInput
						label='Selektor für DeliveryTime'
						source='selectorDeliveryTime'
						disabled
					/>
					<TextInput
						label='Selektor für Description'
						source='selectorDescription'
						disabled
					/>
					<TextInput
						label='Selektor für Brand'
						source='selectorBrand'
						disabled
					/>
					<TextInput
						label='Selektor für Rating Value'
						source='selectorRatingValue'
						disabled
					/>
					<TextInput
						label='Selektor für Rating Count'
						source='selectorRatingCount'
						disabled
					/>
					<TextInput
						label='Selektor für Amount 1'
						source='selectorAmount1'
						disabled
					/>
					<TextInput
						label='Selektor für Unit 1'
						source='selectorUnit1'
						disabled
					/>
					<TextInput
						label='Selektor für Amount und Unit 1'
						source='selectorAmountUnit1'
						disabled
					/>
					<TextInput
						label='Selektor für Amount 2'
						source='selectorAmount2'
						disabled
					/>
					<TextInput
						label='Selektor für Unit 2'
						source='selectorUnit2'
						disabled
					/>
					<TextInput
						label='Selektor für Amount und Unit 2'
						source='selectorAmountUnit2'
						disabled
					/>
					<TextInput
						label='Selektor für Category'
						source='selectorCategory'
						disabled
					/>
					<TextInput
						label='Selektor für Breadcrumbs'
						source='selectorBreadcrumbs'
						disabled
					/>
					{/* <TextInput label="Selektor für Suggested Retail Price (UVP / AVP)" source="selectorSrp" />*/}
					{/*            <TextInput label="Selektor für Amount" source="selectorAmount" />
                    <TextInput label="Selektor für NormalizedPrice" source="selectorNormalizedPrice" />
                    <TextInput label="Selektor für Unit" source="selectorUnit" />
                    <TextInput label="Selektor für Multiplier" source="selectorMultiplier" />
                    <TextInput label="Selektor für ContainerAmount" source="selectorContainerAmount" />*/}
				</Grid>
				<Grid item xs={6}>
					<TextInput
						label='Wert für Name'
						source='expectedName'
						disabled
					/>
					<TextInput
						label='Wert für Manufacturer'
						source='expectedManufacturer'
						disabled
					/>
					<TextInput
						label='Wert für Price'
						source='expectedPrice'
						disabled
					/>
					<TextInput
						label='Wert für Currency'
						source='expectedCurrency'
						disabled
					/>
					<TextInput
						label='Wert für ArticleNumberOne'
						source='expectedArticleNumberOne'
						disabled
					/>
					<TextInput
						label='Wert für ArticleNumberTwo'
						source='expectedArticleNumberTwo'
						disabled
					/>
					<TextInput
						label='Wert für ArticleNumberThree'
						source='expectedArticleNumberThree'
						disabled
					/>
					<TextInput
						label='Wert für ImgUrl'
						source='expectedImgUrl'
						disabled
					/>
					<TextInput
						label='Wert für StockStatus'
						source='expectedStockStatus'
						disabled
					/>
					<TextInput
						label='Wert für DeliveryTime'
						source='expectedDeliveryTime'
						disabled
					/>
					<TextInput
						label='Wert für Description'
						source='expectedDescription'
						disabled
					/>
					<TextInput
						label='Wert für Brand'
						source='expectedBrand'
						disabled
					/>
					<TextInput
						label='Wert für Rating Value'
						source='expectedRatingValue'
						disabled
					/>
					<TextInput
						label='Wert für Rating Count'
						source='expectedRatingCount'
						disabled
					/>
					<TextInput
						label='Wert für Amount 1'
						source='expectedAmount1'
						disabled
					/>
					<TextInput
						label='Wert für Unit 1'
						source='expectedUnit1'
						disabled
					/>
					<TextInput
						label='Wert für Amount und Unit 1'
						source='expectedAmountUnit1'
						disabled
					/>
					<TextInput
						label='Wert für Amount 2'
						source='expectedAmount2'
						disabled
					/>
					<TextInput
						label='Wert für Unit 2'
						source='expectedUnit2'
						disabled
					/>
					<TextInput
						label='Wert für Amount und Unit 2'
						source='expectedAmountUnit2'
						disabled
					/>
					<TextInput
						label='Wert für Category'
						source='expectedCategory'
						disabled
					/>
					<TextInput
						label='Wert für Breadcrumbs'
						source='expectedBreadcrumbs'
						disabled
					/>
					{/* <TextInput label="Wert für Suggested Retail Price (UVP / AVP)" source="expectedSrp" />*/}
					{/*            <TextInput label="Wert für Amount" source="expectedAmount" />
                    <TextInput label="Wert für NormalizedPrice" source="expectedNormalizedPrice" />
                    <TextInput label="Wert für Unit" source="expectedUnit" />
                    <TextInput label="Wert für Multiplier" source="expectedMultiplier" />
                    <TextInput label="Wert für ContainerAmount" source="expectedContainerAmount" />*/}
				</Grid>
			</Grid>
		</>
	);
};
