import { Divider, Typography } from '@mui/material';
import { TextInput } from 'react-admin';
import * as React from 'react';

function SelectorsEdit({ label = 'Selektoren' }) {
	return (
		<>
			<Typography variant={'h5'}>{label}</Typography>
			<TextInput helperText={false} label='Name' source='selectorName' />
			<TextInput
				helperText={false}
				label='ArticleNumberOne'
				source='selectorArticleNumberOne'
			/>
			<TextInput
				helperText={false}
				label='ArticleNumberTwo'
				source='selectorArticleNumberTwo'
			/>
			<TextInput
				helperText={false}
				label='ArticleNumberThree'
				source='selectorArticleNumberThree'
			/>
			<TextInput
				helperText={false}
				label='Price'
				source='selectorPrice'
			/>
			<TextInput
				helperText={false}
				label='Currency'
				source='selectorCurrency'
			/>
			<TextInput
				helperText={false}
				label='Manufacturer'
				source='selectorManufacturer'
			/>
			<TextInput
				helperText={false}
				label='ImgUrl'
				source='selectorImgUrl'
			/>
			<TextInput
				helperText={false}
				label='StockStatus'
				source='selectorStockStatus'
			/>
			<TextInput
				helperText={false}
				label='DeliveryTime'
				source='selectorDeliveryTime'
			/>
			<TextInput
				helperText={false}
				label='Description'
				source='selectorDescription'
			/>
			<TextInput
				helperText={false}
				label='Brand'
				source='selectorBrand'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Selektoren für Bezeichnung (z.B. Flasche, Tabletten, Stück)
			</Typography>
			<TextInput
				label='Amount'
				source='selectorAmount1'
				helperText={"z.B. für '20'"}
			/>
			<TextInput
				label='Unit'
				source='selectorUnit1'
				helperText={"z.B. für 'Tabletten'"}
			/>
			<TextInput
				label='Amount-Unit'
				source='selectorAmountUnit1'
				helperText={
					"Wenn der Wert nicht getrennt verfügbar ist z.B. für '20 Tabletten'"
				}
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Selektoren für Menge (z.B. ML, Liter, Gramm, KG)
			</Typography>
			<TextInput
				label='Amount'
				source='selectorAmount2'
				helperText={"z.B. für '150'"}
			/>
			<TextInput
				label='Unit'
				source='selectorUnit2'
				helperText={"z.B. für 'Milliliter'"}
			/>
			<TextInput
				label='Amount-Unit'
				source='selectorAmountUnit2'
				helperText={
					"Wenn der Wert nicht getrennt verfügbar ist z.B. für '150 Milliliter'"
				}
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Selektoren für Bewertungen</Typography>
			<TextInput
				helperText={false}
				label='Rating Value'
				source='selectorRatingValue'
			/>
			<TextInput
				helperText={false}
				label='Rating Count'
				source='selectorRatingCount'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Selektoren für Kategorien etc.</Typography>
			<TextInput
				helperText={false}
				label='Category'
				source='selectorCategory'
			/>
			<TextInput
				helperText={false}
				label='Breadcrumbs'
				source='selectorBreadcrumbs'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Weitere Selektoren</Typography>
			<TextInput
				helperText={false}
				label='Suggested Retail Price (UVP / AVP)'
				source='selectorSrp'
			/>
			<TextInput
				helperText={false}
				label='NormalizedPrice'
				source='selectorNormalizedPrice'
			/>
		</>
	);
}

export default SelectorsEdit;
