import { Box, Divider, IconButton, Typography } from '@mui/material';
import { TextInput } from 'react-admin';
import * as React from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useFormContext } from 'react-hook-form';

function SwitchButton({ source }: { source: string }) {
	const { getValues, setValue } = useFormContext();
	const switchVal = () => {
		const columnVal = getValues(source);
		const name = 's' + source.slice(4);
		setValue(name, columnVal);

		// set newExpected
		const newExpectedName = source.replace('Selector', 'Expected');
		const columnValExpected = getValues(newExpectedName);
		if (columnValExpected) {
			const name = 'e' + newExpectedName.slice(4);
			debugger;
			setValue(name, columnValExpected);
		}
	};

	return (
		<IconButton
			aria-label='move'
			size='large'
			sx={{ '& > svg': { verticalAlign: 'text-bottom' } }}
			onClick={switchVal}
		>
			<KeyboardBackspaceIcon fontSize='inherit' />
		</IconButton>
	);
}

function SelectorsNew({ label = 'Selektoren' }) {
	return (
		<>
			<Typography variant={'h5'}>{label}</Typography>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorName' />
				<TextInput
					helperText={false}
					label='Name'
					source='newSelectorName'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorArticleNumberOne' />
				<TextInput
					helperText={false}
					label='ArticleNumberOne'
					source='newSelectorArticleNumberOne'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorArticleNumberTwo' />
				<TextInput
					helperText={false}
					label='ArticleNumberTwo'
					source='newSelectorArticleNumberTwo'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorArticleNumberThree' />
				<TextInput
					helperText={false}
					label='ArticleNumberThree'
					source='newSelectorArticleNumberThree'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorPrice' />
				<TextInput
					helperText={false}
					label='Price'
					source='newSelectorPrice'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorCurrency' />
				<TextInput
					helperText={false}
					label='Currency'
					source='newSelectorCurrency'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorManufacturer' />
				<TextInput
					helperText={false}
					label='Manufacturer'
					source='newSelectorManufacturer'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorImgUrl' />
				<TextInput
					helperText={false}
					label='ImgUrl'
					source='newSelectorImgUrl'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorStockStatus' />
				<TextInput
					helperText={false}
					label='StockStatus'
					source='newSelectorStockStatus'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorDeliveryTime' />
				<TextInput
					helperText={false}
					label='DeliveryTime'
					source='newSelectorDeliveryTime'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorDescription' />
				<TextInput
					helperText={false}
					label='Description'
					source='newSelectorDescription'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorBrand' />
				<TextInput
					helperText={false}
					label='Brand'
					source='newSelectorBrand'
				/>
			</Box>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Selektoren für Bezeichnung (z.B. Flasche, Tabletten, Stück)
			</Typography>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorAmount1' />
				<TextInput
					label='Amount'
					source='newSelectorAmount1'
					helperText={"z.B. für '20'"}
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorUnit1' />
				<TextInput
					label='Unit'
					source='newSelectorUnit1'
					helperText={"z.B. für 'Tabletten'"}
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorAmountUnit1' />
				<TextInput
					label='Amount-Unit'
					source='newSelectorAmountUnit1'
					helperText={
						"Wenn der Wert nicht getrennt verfügbar ist z.B. für '20 Tabletten'"
					}
				/>
			</Box>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Selektoren für Menge (z.B. ML, Liter, Gramm, KG)
			</Typography>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorAmount2' />
				<TextInput
					label='Amount'
					source='newSelectorAmount2'
					helperText={"z.B. für '150'"}
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorUnit2' />
				<TextInput
					label='Unit'
					source='newSelectorUnit2'
					helperText={"z.B. für 'Milliliter'"}
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorAmountUnit2' />
				<TextInput
					label='Amount-Unit'
					source='newSelectorAmountUnit2'
					helperText={
						"Wenn der Wert nicht getrennt verfügbar ist z.B. für '150 Milliliter'"
					}
				/>
			</Box>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Selektoren für Bewertungen</Typography>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorRatingValue' />
				<TextInput
					helperText={false}
					label='Rating Value'
					source='newSelectorRatingValue'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorRatingCount' />
				<TextInput
					helperText={false}
					label='Rating Count'
					source='newSelectorRatingCount'
				/>
			</Box>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Selektoren für Kategorien etc.</Typography>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorCategory' />
				<TextInput
					helperText={false}
					label='Category'
					source='newSelectorCategory'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorBreadcrumbs' />
				<TextInput
					helperText={false}
					label='Breadcrumbs'
					source='newSelectorBreadcrumbs'
				/>
			</Box>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Weitere Selektoren</Typography>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorSrp' />
				<TextInput
					helperText={false}
					label='Suggested Retail Price (UVP / AVP)'
					source='newSelectorSrp'
				/>
			</Box>
			<Box display={'flex'}>
				<SwitchButton source='newSelectorNormalizedPrice' />
				<TextInput
					helperText={false}
					label='NormalizedPrice'
					source='newSelectorNormalizedPrice'
				/>
			</Box>
		</>
	);
}

export default SelectorsNew;
