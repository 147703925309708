import { Divider, Typography } from '@mui/material';
import { TextInput } from 'react-admin';
import * as React from 'react';

function ExpectedValuesNewEdit() {
	return (
		<>
			<Typography variant={'h5'}>Gefundene Erwartete Werte</Typography>
			<TextInput
				helperText={false}
				label='Name'
				source='newExpectedName'
			/>
			<TextInput
				helperText={false}
				label='ArticleNumberOne'
				source='newExpectedArticleNumberOne'
			/>
			<TextInput
				helperText={false}
				label='ArticleNumberTwo'
				source='newExpectedArticleNumberTwo'
			/>
			<TextInput
				helperText={false}
				label='ArticleNumberThree'
				source='newExpectedArticleNumberThree'
			/>
			<TextInput
				helperText={false}
				label='Price'
				source='newExpectedPrice'
			/>
			<TextInput
				helperText={false}
				label='Currency'
				source='newExpectedCurrency'
			/>
			<TextInput
				helperText={false}
				label='Manufacturer'
				source='newExpectedManufacturer'
			/>
			<TextInput
				helperText={false}
				label='ImgUrl'
				source='newExpectedImgUrl'
			/>
			<TextInput
				helperText={false}
				label='StockStatus'
				source='newExpectedStockStatus'
			/>
			<TextInput
				helperText={false}
				label='DeliveryTime'
				source='newExpectedDeliveryTime'
			/>
			<TextInput
				helperText={false}
				label='Description'
				source='newExpectedDescription'
			/>
			<TextInput
				helperText={false}
				label='Brand'
				source='newExpectedBrand'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Erwartete Werte für Bezeichnung (z.B. Flasche, Tabletten, Stück)
			</Typography>
			<TextInput
				helperText={"z.B. für '20'"}
				label='Amount'
				source='newExpectedAmount1'
			/>
			<TextInput
				helperText={"z.B. für 'Tabletten'"}
				label='Unit'
				source='newExpectedUnit1'
			/>
			<TextInput
				label='Amount-Unit'
				source='newExpectedAmountUnit1'
				helperText={'Wenn der Wert nicht getrennt verfügbar ist'}
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Erwartete Werte für Menge (z.B. ML, Liter, Gramm, KG)
			</Typography>
			<TextInput
				helperText={"z.B. für '150'"}
				label='Amount'
				source='newExpectedAmount2'
			/>
			<TextInput
				helperText={"z.B. für 'Milliliter'"}
				label='Unit'
				source='newExpectedUnit2'
			/>
			<TextInput
				label='Amount-Unit'
				source='newExpectedAmountUnit2'
				helperText={'Wenn der Wert nicht getrennt verfügbar ist'}
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Erwartete Werte für Bewertungen</Typography>
			<TextInput
				helperText={false}
				label='Rating Value'
				source='newExpectedRatingValue'
			/>
			<TextInput
				helperText={false}
				label='Rating Count'
				source='newExpectedRatingCount'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Erwartete Werte für Kategorien etc.</Typography>
			<TextInput
				helperText={false}
				label='Category'
				source='newExpectedCategory'
			/>
			<TextInput
				helperText={false}
				label='Breadcrumbs'
				source='newExpectedBreadcrumbs'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Weitere Erwartete Werte</Typography>
			<TextInput
				helperText={false}
				label='Suggested Retail Price (UVP / AVP)'
				source='newExpectedSrp'
			/>
			<TextInput
				helperText={false}
				label='NormalizedPrice'
				source='newExpectedNormalizedPrice'
			/>
		</>
	);
}

export default ExpectedValuesNewEdit;
