import { Divider, Typography } from '@mui/material';
import { TextInput } from 'react-admin';
import * as React from 'react';

function ExpectedValuesEdit() {
	return (
		<>
			<Typography variant={'h5'}>Erwartete Werte</Typography>
			<TextInput helperText={false} label='Name' source='expectedName' />
			<TextInput
				helperText={false}
				label='ArticleNumberOne'
				source='expectedArticleNumberOne'
			/>
			<TextInput
				helperText={false}
				label='ArticleNumberTwo'
				source='expectedArticleNumberTwo'
			/>
			<TextInput
				helperText={false}
				label='ArticleNumberThree'
				source='expectedArticleNumberThree'
			/>
			<TextInput
				helperText={false}
				label='Price'
				source='expectedPrice'
			/>
			<TextInput
				helperText={false}
				label='Currency'
				source='expectedCurrency'
			/>
			<TextInput
				helperText={false}
				label='Manufacturer'
				source='expectedManufacturer'
			/>
			<TextInput
				helperText={false}
				label='ImgUrl'
				source='expectedImgUrl'
			/>
			<TextInput
				helperText={false}
				label='StockStatus'
				source='expectedStockStatus'
			/>
			<TextInput
				helperText={false}
				label='DeliveryTime'
				source='expectedDeliveryTime'
			/>
			<TextInput
				helperText={false}
				label='Description'
				source='expectedDescription'
			/>
			<TextInput
				helperText={false}
				label='Brand'
				source='expectedBrand'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Erwartete Werte für Bezeichnung (z.B. Flasche, Tabletten, Stück)
			</Typography>
			<TextInput
				helperText={"z.B. für '20'"}
				label='Amount'
				source='expectedAmount1'
			/>
			<TextInput
				helperText={"z.B. für 'Tabletten'"}
				label='Unit'
				source='expectedUnit1'
			/>
			<TextInput
				label='Amount-Unit'
				source='expectedAmountUnit1'
				helperText={'Wenn der Wert nicht getrennt verfügbar ist'}
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>
				Erwartete Werte für Menge (z.B. ML, Liter, Gramm, KG)
			</Typography>
			<TextInput
				helperText={"z.B. für '150'"}
				label='Amount'
				source='expectedAmount2'
			/>
			<TextInput
				helperText={"z.B. für 'Milliliter'"}
				label='Unit'
				source='expectedUnit2'
			/>
			<TextInput
				label='Amount-Unit'
				source='expectedAmountUnit2'
				helperText={'Wenn der Wert nicht getrennt verfügbar ist'}
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Erwartete Werte für Bewertungen</Typography>
			<TextInput
				helperText={false}
				label='Rating Value'
				source='expectedRatingValue'
			/>
			<TextInput
				helperText={false}
				label='Rating Count'
				source='expectedRatingCount'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Erwartete Werte für Kategorien etc.</Typography>
			<TextInput
				helperText={false}
				label='Category'
				source='expectedCategory'
			/>
			<TextInput
				helperText={false}
				label='Breadcrumbs'
				source='expectedBreadcrumbs'
			/>
			<Divider sx={{ width: '100%', my: 2 }} />
			<Typography>Weitere Erwartete Werte</Typography>
			<TextInput
				helperText={false}
				label='Suggested Retail Price (UVP / AVP)'
				source='expectedSrp'
			/>
			<TextInput
				helperText={false}
				label='NormalizedPrice'
				source='expectedNormalizedPrice'
			/>
		</>
	);
}

export default ExpectedValuesEdit;
