import * as React from 'react';
import { TextInput } from 'react-admin';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import axios from 'axios';

export const SelectorsStep = (props: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const { getValues, setValue } = useFormContext();
	const handleSearch = () => {
		setIsLoading(true);
		const formData = getValues();

		axios
			.post(
				process.env.REACT_APP_HUB_API_URL +
					'/shops/get-selectors-by-expected-values',
				{ formData: formData }	, { withCredentials: true }
			)
			.then((response) => {
				const data = response.data;
				for (let item in data) {
					if (item.includes('selector') && item !== '') {
						const hasValue = formData[item];
						if (!hasValue) {
							setValue(item, response.data[item]);
						}
					}
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	return (
		<Box sx={{ '& > .MuiFormControl-root': { width: '100%' } }}>
			<Typography
				variant='h6'
				gutterBottom
				sx={{
					borderBottom: '1px solid',
					width: '100%',
					marginBottom: '25px'
				}}
			>
				Bearbeiten der Selektoren. Suchen von CSS Selektoren.
			</Typography>
			<Typography variant='body1' sx={{ marginBottom: 1 }}>
				CSS Selektoren suchen?{' '}
				<strong>Es werden nur leere Selektoren überschrieben!</strong>
			</Typography>
			<Button
				variant={'contained'}
				sx={{ mb: 2 }}
				onClick={handleSearch}
				disabled={isLoading}
			>
				CSS Selektoren Suchen{' '}
				{isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
			</Button>
			<TextInput label='Selektor für Name' source='selectorName' />
			<TextInput
				label='Selektor für ArticleNumberOne'
				source='selectorArticleNumberOne'
			/>
			<TextInput
				label='Selektor für ArticleNumberTwo'
				source='selectorArticleNumberTwo'
			/>
			<TextInput
				label='Selektor für ArticleNumberThree'
				source='selectorArticleNumberThree'
			/>
			<TextInput label='Selektor für Price' source='selectorPrice' />
			<TextInput
				label='Selektor für Suggested Retail Price (UVP / AVP)'
				source='selectorSrp'
			/>
			<TextInput
				label='Selektor für Manufacturer'
				source='selectorManufacturer'
			/>
			<TextInput label='Selektor für ImgUrl' source='selectorImgUrl' />
			<TextInput
				label='Selektor für StockStatus'
				source='selectorStockStatus'
			/>
			<TextInput
				label='Selektor für DeliveryTime'
				source='selectorDeliveryTime'
			/>
			<TextInput
				label='Selektor für Description'
				source='selectorDescription'
			/>
			<TextInput
				label='Selektor für Brand'
				source='selectorBrand'
			/>
			<TextInput label='Selektor für Amount' source='selectorAmount' />
			<TextInput
				label='Selektor für NormalizedPrice'
				source='selectorNormalizedPrice'
			/>
			<TextInput label='Selektor für Unit' source='selectorUnit' />
			<TextInput
				label='Selektor für Multiplier'
				source='selectorMultiplier'
			/>
			<TextInput
				label='Selektor für ContainerAmount'
				source='selectorContainerAmount'
			/>
			<TextInput
				label='Selektor für Rating Value'
				source='selectorRatingValue'
			/>
			<TextInput
				label='Selektor für Rating Count'
				source='selectorRatingCount'
			/>
			<TextInput label='Selektor für Amount 1' source='selectorAmount1' />
			<TextInput label='Selektor für Unit 1' source='selectorUnit1' />
			<TextInput
				label='Selektor für Amount und Unit 1'
				source='selectorAmountUnit1'
			/>
			<TextInput label='Selektor für Amount 2' source='selectorAmount2' />
			<TextInput label='Selektor für Unit 2' source='selectorUnit2' />
			<TextInput
				label='Selektor für Amount und Unit 2'
				source='selectorAmountUnit2'
			/>
			<TextInput
				label='Selektor für Category'
				source='selectorCategory'
			/>
			<TextInput
				label='Selektor für Breadcrumbs'
				source='selectorBreadcrumbs'
			/>
			<TextInput
				label='Selektor für Currency'
				source='selectorCurrency'
			/>
		</Box>
	);
};
